import './bootstrap';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import i18n from './i18n';
import ToastPlugin from 'vue-toast-notification';
import {ZiggyVue} from 'ziggy-js';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
        app.use(plugin);
        app.use(i18n);
        app.use(ZiggyVue);
        app.use(ToastPlugin);
        app.mount(el);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
