import { createI18n } from 'vue-i18n';
import {reactive} from "vue";

// Load locale messages
function loadLocaleMessages() {
    const locales = import.meta.glob('../lang/inertia/*.json', { eager: true });
    const messages = {};
    for (const path in locales) {
        const matched = path.match(/\/([^/]+)\.json$/); // path.match(/\/locales\/(.*)\.json$/);
        if (matched && matched[1]) {
            const locale = matched[1];
            messages[locale] = locales[path].default;
        }
    }
    return messages;
}

const i18n = createI18n({
    legacy: false, // Use the Composition API
    locale: loadLocale(),
    fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});

export default i18n;

function loadLocale() {
    return localStorage.getItem('locale') || import.meta.env.VITE_DEFAULT_LOCALE || 'en';
}
